<template>
    <div>
        <a-card style="background-color: #fff;">
            <div :class="advanced ? 'search' : null">
                <a-form layout="horizontal">
                    <div :class="advanced ? null : 'fold'">
                        <a-row>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="栏目" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.classifyType" placeholder="请选择栏目"
                                        option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in menuList" :key="item.id" :label="item.name" >{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="分销商" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.distributorCode" placeholder="请选择分销商" option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in codeList" :key="item.id" :label="item.name" @click="selectCode(item.id)">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="一级分类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.firstClassifyId" placeholder="请选择一级分类" option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in firstList" :key="item.id" :label="item.name" @click="pIdChange(item.id,0)">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="二级分类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.secondClassifyId" placeholder="请选择二级分类" option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in secondList" :key="item.id" :label="item.name" @click="pIdChange(item.id,1)">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="三级分类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.tertiaryClassifyId" placeholder="请选择三级分类" option-label-prop="label">
                                        <a-select-option :value="item.id" v-for="item in tertiaryList" :key="item.id" :label="item.name" @click="pIdChange(item.pId,2)">{{ item.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="平台状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.status" placeholder="请选择平台状态"
                                        option-label-prop="label">
                                        <a-select-option :value="1" label="上架">上架</a-select-option>
                                        <a-select-option :value="2" label="下架">下架</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="三方状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-select allowClear v-model="queryData.state" placeholder="请选择三方状态"
                                        option-label-prop="label">
                                        <a-select-option :value="1" label="上架">上架</a-select-option>
                                        <a-select-option :value="2" label="下架">下架</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="入库时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-range-picker :value="dateValue"
                                        :ranges="{ '今天': [moment(), moment()], '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')], '当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] }"
                                        @change="dateChange" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="关键词" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-input v-model="queryData.name" placeholder="请输入产品短称/商品关键词" />
                                </a-form-item>
                            </a-col>

                            <a-col :md="8" :sm="24">
                                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    @click="search">查询</a-button>
                                <a-button  type="primary"
                                    style="margin-top: 4px;margin-left: 10px;" @click="exportTask">导出</a-button>
                            </a-col>
                        </a-row>
                        <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
            queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                    </div>

                </a-form>

            </div>


            <div>
                <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                    <div slot="createTime" slot-scope="{text}">{{ formatDate(text) }}</div>
                    <div slot="status" slot-scope="{text}">
                        <span v-if="text == 1" style="color: #008000;">上架</span>
                        <span v-if="text == 2" style="color: #ff0000;">下架</span>
                    </div>
                    <div slot="state" slot-scope="{text}">
                        <span v-if="text == 1" style="color: #008000;">上架</span>
                        <span v-if="text == 2" style="color: #ff0000;">下架</span>
                    </div>
                    <div slot="imagePath" slot-scope="{text}"><img style="width: 100px;" :src="text" alt=""></div>

                </standard-table>
                <div class="page-box">
                    <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer />
                </div>
            </div>
        </a-card>

        <!-- <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
            <a-empty :description="false" />
            无权限查看
        </div> -->
    </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { publicMixin } from '@/mixin/public';
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    {
        title: '品牌名称',
        dataIndex: 'name',
        width: '30%',
        align: 'center'

    },
    {
        title: '产品图片',
        dataIndex: 'imagePath',
        scopedSlots: { customRender: 'imagePath' },
        align: 'center'
    },
    {
        title: '小程序菜单-分销商-一级分类-二级分类-三级分类',
        dataIndex: 'classifyName',
        width: '20%',
        align: 'center'
    },
    {
        title: '状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        width: '10%',
        align: 'center'
    },
    {
        title: '三方状态',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
        width: '10%',
        align: 'center'
    },
    {
        title: '入库时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
        width: '15%',
        align: 'center'
    },

    // {
    //     title: '操作',
    //     scopedSlots: { customRender: 'action' },
    //     align: 'center'
    // }
]

export default {
    name: 'QueryList',
    components: { StandardTable },
    mixins: [publicMixin],
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            menuList:[{id:1,name:'商城'},{id:3,name:'蛋糕'},{id:4,name:'礼包'},{id:5,name:'生活'},{id:6,name:'演出'},{id:7,name:'图书'}],
            codeList:[{id:'20220601001',name:'优加惠品'},{id:'20221212001',name:'好乐滋'}],
            firstList:[],//一级分类
            secondList:[],//二级分类
            tertiaryList:[],//三级分类
            pId:0,
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        }
    },
   watch:{
    'queryData.classifyType': function(newVal,oldVal) {
      if(newVal != oldVal) {
            delete this.queryData.distributorCode;
            delete this.queryData.firstClassifyId
            delete this.queryData.secondClassifyId;
            delete this.queryData.tertiaryClassifyId;
            this.firstList = []
            this.secondList = []
            this.tertiaryList = [];
            this.pId = 0;
        }
    },
    'queryData.distributorCode': function (newVal,oldVal) {
        if(newVal != oldVal) {
            delete this.queryData.firstClassifyId
            delete this.queryData.secondClassifyId;
            delete this.queryData.tertiaryClassifyId;
            this.firstList = []
            this.secondList = []
            this.tertiaryList = [];
            this.pId = 0;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' +this.pId + '/' + newVal, 'get').then(res => {
                this.firstList = res.data.data;
            })
        }
        if (newVal) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' +this.pId + '/' + newVal, 'get').then(res => {
                this.firstList = res.data.data;
            })
        } 
    },
    'queryData.firstClassifyId':function(){
        if (this.queryData.firstClassifyId) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType +  '/' + this.pId + '/' + this.queryData.distributorCode, 'get').then(res => {
                this.secondList = res.data.data;
            })
        }
    },
    'queryData.secondClassifyId':function(){
        if (this.queryData.secondClassifyId) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + this.queryData.distributorCode, 'get').then(res => {
                this.tertiaryList = res.data.data;
            })
        }
    },
   },
    methods: {
        pIdChange(pId,type){
            if(type == 0) {
                delete this.queryData.secondClassifyId;
                this.secondList = []
                delete this.queryData.tertiaryClassifyId;
                this.tertiaryList = [];
            }
            if(type == 1) {
                delete this.queryData.tertiaryClassifyId;
                this.tertiaryList = [];
            }
            this.pId = pId;
        },
        selectCode(id){
            if(!this.queryData.classifyType) {
                this.queryData.distributorCode = null;
                this.$message.warning('请先选择小程序菜单')
                return false;
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' +this.pId + '/' + id, 'get').then(res => {
                    this.firstList = res.data.data;
                })
            }
            
        },
        provChange(id) {
            this.city = []
            this.queryData.cityList = []
            if (!id) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                    this.city.forEach(item => {
                        this.queryData.cityList.push(item.name)
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
      
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
            }
            this.dateValue = ''
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/es/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
     
        exportTask() {
            this.$message.loading('处理中')
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/mall/exportClassifyGoodsNum', 'post', {remarks:'',classifyType:this.queryData.classifyType,distributorCode:this.queryData.distributorCode}).then(res => {
                this.$message.destroy();
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },


    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>